import * as Sentry from "@sentry/sveltekit";
import { dev } from "$app/environment";
import publicEnv from '$lib/publicEnv';

// the delay fixes https://github.com/getsentry/sentry-javascript/issues/9252#issuecomment-1841145526
setTimeout(() => {
  const sentryEnabled = !dev
  const sentryEnvironment = publicEnv.PUBLIC_APP_ENV
  const sentryRelease = publicEnv.PUBLIC_APP_VERSION

  console.log(`sentry: ${sentryEnabled ? sentryEnvironment : 'false'}`);
  Sentry.init({
    dsn: "https://f847a05898d50e63d85098536a6e214d@o4506434876669952.ingest.sentry.io/4506434880012288",
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    profilesSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      new Sentry.BrowserProfilingIntegration(),
    ],
    enabled: sentryEnabled, // only enable for production/staging
    environment: sentryEnvironment,
    release: sentryRelease
  });
}, 10);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
