import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/": [~4],
		"/(auth)/admin/impersonate": [~5],
		"/dashboard": [~12,[2]],
		"/dashboard/admin-claim": [~13,[2]],
		"/dashboard/admin-claim/ghost/[id]": [~15,[2]],
		"/dashboard/admin-claim/new/ghost/[id]": [~17,[2]],
		"/dashboard/admin-claim/new/[id]": [~16,[2]],
		"/dashboard/admin-claim/[id]": [~14,[2]],
		"/dashboard/admin-extension": [~18,[2]],
		"/dashboard/admin-review": [~19,[2]],
		"/dashboard/admin-review/new/[jobId]": [~21,[2]],
		"/dashboard/admin-review/original/[jobId]": [~22,[2]],
		"/dashboard/admin-review/[jobId]": [~20,[2]],
		"/dashboard/editor/[id]": [~24,[2]],
		"/dashboard/edit/[id]": [~23,[2]],
		"/dashboard/feedback": [~25,[2]],
		"/dashboard/feedback/old": [~27,[2]],
		"/dashboard/feedback/old/[id]": [~28,[2]],
		"/dashboard/feedback/[id]": [~26,[2]],
		"/dashboard/settings": [~29,[2]],
		"/(auth)/login": [~6],
		"/mobile": [30],
		"/paypal/admin": [~31],
		"/paypal/admin/refresh": [~32],
		"/paypal/admin/return": [~33],
		"/paypal/login": [~34],
		"/paypal/return": [~35],
		"/(auth)/request-reset": [~7],
		"/(auth)/reset": [~8],
		"/(auth)/signup": [~9],
		"/stripe/dashboard": [~36],
		"/stripe/refresh": [~37],
		"/stripe/return": [~38],
		"/test": [~39,[],[3]],
		"/test/[id]": [~40,[],[3]],
		"/(auth)/verify-email": [~11],
		"/(auth)/verify": [~10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';